



















































import * as _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import Vuetable from "vuetable-2";

@Component({
  components: {
    Vuetable,
  },
})
export default class MailTemplatesTable extends Vue {
  $refs!: Vue["$refs"] & {
    mailstable: Vuetable;
  };
  isLoading = false;
  mailIndex = null;
  perPage = 5000;
  tableFields = [
    { name: "name", sortField: "name" },
    { name: "subject", sortField: "subject" },
    { name: "action_url", sortField: "action_url" },
    { name: "created_at", sortField: "created_at" },
    { name: "actions" },
  ];

  get mails() {
    return this.$store.state.Mails.mails;
  }

  dataManager(sortOrder, pagination) {
    if (this.mails.data.length < 1) return;

    let local = this.mails.data;

    // sortOrder can be empty, so we have to check for that as well
    if (sortOrder.length > 0) {
      local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
    }

    pagination = this.$refs.mailstable.makePagination(
      local.length,
      this.perPage
    );
    let from = pagination.from - 1;
    let to = from + this.perPage;

    return {
      pagination: pagination,
      data: local,
    };
  }

  async duplicateMail(mailToDuplicate: any) {
    this.isLoading = true;
    const { id, ...mail } = mailToDuplicate;
    mail.name = `${mail.name} (copy)`;
    await this.$store.dispatch("Mails/create", mail);
    this.$root.$bvToast.toast("Mail duplicated!", {
      variant: "success",
    });
    await this.$store.dispatch("Mails/fetchAll");
    this.isLoading = false;
  }

  addMail() {
    this.$router.push({ path: "mail-template-detail" });
  }

  editMail(mail: any) {
    this.$router.push({
      path: "mail-template-detail",
      query: { id: mail.id },
    });
  }

  async deleteMail(mail: any) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove mail "${mail.subject}"?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.$store.dispatch("Mails/delete", mail.id);
      await this.$store.dispatch("Mails/fetchAll");
    }
    this.isLoading = false;
  }

  async created() {
    await this.$store.dispatch("Mails/fetchAll");
  }
}
